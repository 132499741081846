<template>
  <div>
    <div class="device-fixed" :class="{'fixed':deviceFixed, 'show':deviceFixedShow}" id="device-fixed">
      <b-row class="match-height">
        <b-col cols="12" xl="12">
          <b-card no-body>
            <b-card-body>
              <div class="device-top d-flex flex-column flex-xl-row align-items-xl-center align-items-start">
                <b-card-title class="text-device mb-0">
                  {{ device ? device.name || device.model_code  : ""}}
                </b-card-title>
                <div class="device-right">
                  <b-button
                      class="device-btn"
                      variant="none"
                      data-id="01"
                      @click="gobacktodevice()"
                  >
                    <feather-icon class="device-icon" icon="CheckSquareIcon"/>
                    <span class="align-middle">{{ $t("Fit for Use") }}</span>
                  </b-button>
                  <b-button
                      class="device-btn"
                      variant="none"
                      data-id="02"
                      @click="gobacktodevice()"
                  >
                    <feather-icon class="device-icon" icon="CreditCardIcon"/>
                    <span class="align-middle">{{ $t("Ambient") }}</span>
                  </b-button>
                  <b-button
                      class="device-btn"
                      variant="none"
                      data-id="03"
                      @click="gobacktodevice()"
                  >
                    <feather-icon class="device-icon" icon="TrendingUpIcon"/>
                    <span class="align-middle">{{$t("Performance")}}</span>
                  </b-button>
                  <b-button
                      class="device-btn"
                      variant="none"
                      data-id="04"
                      @click="goToAlerts"
                  >
                    <feather-icon class="device-icon" icon="BellIcon"/>
                    <span class="align-middle">{{ $t("Alerts") }}</span>
                  </b-button>
                  <b-button
                      class="device-btn"
                      variant="none"
                      data-id="05"
                  >
                    <feather-icon class="device-icon" icon="PieChartIcon"/>
                    <span class="align-middle">{{ $t("Reports") }}</span>
                  </b-button>
                  <b-button
                      class="device-btn mr-0"
                      variant="none"
                      data-id="06"
                      @click="gobacktodevice()"
                  >
                    <feather-icon class="device-icon" icon="CheckSquareIcon"/>
                    <span class="align-middle">{{ $t("Details") }}</span>
                  </b-button>
                </div>
                <div class="device-info-wrapper relative d-flex">
                  <p class="serial-number">
                    {{ $t("Serial") }}:
                    <span>{{ device.serial_number }}</span>
                  </p>
                  <p class="model-number">
                    {{ $t("Model") }}:
                    <span>{{ device.model_code }}</span>
                  </p>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VueApexCharts,
  },
  directives: {
    Ripple,
  },
  computed: {
    imageS3() {
      return process.env.VUE_APP_S3_DEVICE;
    },
    device() {
      return this.$store.state.devices.currentDevice;
    },
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    recipes()
    {
      return this.$store.state.recipes.recipes
    }
  },
  data() {
    return {
      deviceFixed: false,
      deviceFixedShow: false,
    };
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
  },
  methods: {
    goToAlerts() {
      this.$router.push({
        name: "device-alerts",
        params: {id: this.$route.params.id},
      });
    },
    gobacktodevice() {
      this.$router.push({
        name: "device",
        params: {id: this.$route.params.id},
      });
    },
  },
};
</script>
