<template>
  <div>
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <validation-observer ref="downloadForm">
        <b-card no-body>
          <b-card-body>
            <b-row class="justify-content-center">
              <b-col md="12" lg="6">
              
                <span
                  class="text-status-reports text-uppercase text-center d-block"
                  >{{$t("Metrics")}}</span
                >
                <div class="pt-1">
                  <b-row class="align-items-center justify-content-center">
                    <b-col md="12" lg="3">
                      <label
                        for="datepicker-placeholder"
                        class="
                          text-status-reports
                          small
                          text-lg-right text-center
                          d-block
                          text-center
                        "
                        >{{$t("Metric")}}:</label
                      >
                    </b-col>
                    <b-col md="12" lg="5">
                      <validation-provider
                          #default="{ errors }"
                          name="Metric Field"
                          rules="required"
                        >
                      <v-select  
                        class="metrics-select"
                        :placeholder="$t('Select an option')"
                        :options=options
                        :searchable="false"
                        v-model="selectedMetricField"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <div class="pt-1">
                  <b-row class="align-items-center justify-content-center">
                    <b-col md="12" lg="3">
                      <label
                        for="data-inicio-03"
                        class="
                          text-status-reports
                          small
                          text-lg-right text-center
                          d-block
                          text-center
                        "
                        >{{$t("Initial Date")}}:</label
                      >
                    </b-col>
                    <b-col md="12" lg="5" class="d-flex justify-content-center">
                      <validation-provider
                          #default="{ errors }"
                          name="Initial Date"
                          rules="required"
                        >
                      <b-form-datepicker
                        id="data-inicio-03"
                        placeholder="dd/mm/yyyy"
                        locale="pt"
                        :hide-header="true"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="initial_date"
                        :state="errors.length > 0 ? false : null"
                        :max="new Date().toISOString()"
                        ></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <div class="pt-1">
                  <b-row class="align-items-center justify-content-center">
                    <b-col md="12" lg="3">
                      <label
                        for="data-fim-04"
                        class="
                          text-status-reports
                          small
                          text-lg-right text-center
                          d-block
                          text-center
                        "
                        >{{$t("End Date")}}:</label
                      >
                    </b-col>
                    <b-col md="12" lg="5" class="d-flex justify-content-center">
                      <validation-provider
                          #default="{ errors }"
                          name="End Date"
                          rules="required"
                        >
                      <b-form-datepicker
                        id="data-fim-04"
                        placeholder="dd/mm/yyyy"
                        locale="pt"
                        :hide-header="true"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                      v-model="end_date"
                      :state="errors.length > 0 ? false : null"
                      :min="initial_date"
                      :max="new Date().toISOString()"
                        ></b-form-datepicker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <div class="pt-1">
                  <b-row class="align-items-center justify-content-center">
                    <b-col md="12" lg="3">
                      <label
                        for="interval-1"
                        class="
                          text-status-reports
                          small
                          text-lg-right text-center
                          d-block
                          text-center
                        "
                        >{{$t("Date Interval")}}:</label
                      >
                    </b-col>
                    <b-col md="12" lg="5" class="d-flex justify-content-center" >
                      <validation-provider
                          #default="{ errors }"
                          name="Interval"
                          rules="required"
                        >
                        <v-select
                        style="width: 150px"
                        :placeholder="$t('Interval')"
                        :options=time_interval_options
                        :searchable="false"
                        v-model="window"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <div class="mt-4 mb-2 text-center">
                  <button 
                  type="submit"
                  class="btn search-btn mr-0 btn-none"
                  @click="validationForm()">
                    <span class="text-uppercase">{{$t("Download CSV report")}}</span>
                  </button>
                  <button 
                  type="submit"
                  class="btn schedule-btn mr-0 btn-none"
                  v-b-modal.modal-center-schedule
                  >
                    <span class="text-uppercase">{{$t("Schedule")}}</span>
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        </validation-observer>
      </b-col>
    </b-row>
    <b-modal
      id="modal-center-schedule"
      ok-only
      centered
      ok-title="Save"
      class="justify-content-center"
      @ok="validationModal"
      @hidden="resetModal"

    >
      <span class="modal-title">{{ "Automatic reports" }}</span>
      <b-row>
        <b-col cols="12" class="mt-1">
          <p>{{ $t("Choose the report frequency and email account: ") }}</p>
        </b-col>
      </b-row>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-row>
        <b-col cols="12">
          <b-form-group label="Frequency:">
            <div class="align-items-center">
              <v-select
                         required
                        :placeholder="$t('Frequency')"
                        :options=frequency_options
                        :searchable="false"
                        v-model="frequency"
                        :getOptionLabel="(option) => `${$t(option.text)}`"
                        :state="frequencyState" 
                      />
            <small v-if="frequencyState === false" class="text-danger">{{ "Frequency is required" }}</small>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Accounts:">
            <div class="align-items-center">
              <v-select
                        multiple
                        :placeholder="$t('Accounts')"
                        :options=emailOptions
                        :searchable="false"
                        v-model="account_options"
                        :state="accountState"
                      />
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Date Interval:">
            <div class="align-items-center">
                        <v-select
                        required
                        :placeholder="$t('Interval')"
                        :options=time_interval_options
                        :searchable="false"
                        v-model="windowSchedule"
                        :state="dateIntervalState"
                      />
              <small v-if="dateIntervalState  === false" class="text-danger">{{ "Interval is required" }}</small>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      </form>
    </b-modal>
  </div>
  
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormDatepicker,
  BFormTimepicker,
  VBModal,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
  VBTooltip,
  BFormSelect,
  BForm
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormDatepicker,
    vSelect,
    ToastificationContent,
    ValidationProvider, 
    ValidationObserver,
    BFormTimepicker,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    VBTooltip,
    BFormSelect,
    BForm
  },
  directives: {
    "b-form-datepicker": BFormDatepicker,
    "b-modal": VBModal,
    // vSelect,
    Ripple,
  },
  data() {
    return {
      options:[
        {
          label:this.$t('Internal Temperature'),
          value:"internal_temperature"
        }
      ],
      selectedMetricField:"",
      initial_date:"",
      end_date:"",
      window:"",
      // frequency: null,
      frequency_options: [
        {value:"Daily", text:"Daily"},
        {value:"Weekly", text:"Weekly"},
        {value:"Monthly", text:"Monthly"},
      ],
      // account_options :null,
      frequencyState:null,
      accountState:null,
      dateIntervalState: null,
      new_frequency: "",
      new_account:[],
      new_window:"",
      time_interval_options : [
        {value:'5m', label: "5m"},
        {value:'10m', label: "10m"},
        {value:'15m', label: "15m"},
        {value:'30m', label: "30m"},
        {value:'1h', label: "1h"},
        {value:'4h', label: "4h"},
        {value:'12h', label: "12h"},
        {value:'24h', label: "24h"},
      ],

      //validation rules
      required
    };
  },
  computed:
  {
    currentDevice()
    {
      return this.$store.state.devices.currentDevice; 
    },
    emailOptions()
    {
      return this.$store.state.devices.email_list_for_device; 
    },
    frequency: {
      get() {
        this.new_frequency = this.$store.state.metrics.schedule_report ? this.$store.state.metrics.schedule_report.frequency : null
        return this.$store.state.metrics.schedule_report ? this.frequency_options.find(x=>x.value == this.$store.state.metrics.schedule_report.frequency) : null;
      },
      set(data) {
        console.log(data)
        if(data){
          this.new_frequency = data.value
          this.$store.commit("metrics/SET_FREQUENCY", data.value);
        }
        else{
          this.new_frequency = data
        }
      },
    },
    account_options: {
      get() {
        this.new_account = this.$store.state.metrics.schedule_report ? this.$store.state.metrics.schedule_report.users_to_send : []
        return this.$store.state.metrics.schedule_report ? this.$store.state.metrics.schedule_report.users_to_send : [];
      },
      set(data) {
        if(data){
          this.new_account = data
          this.$store.commit("metrics/SET_EMAIL_LIST", data);
        }
      },
    },
    windowSchedule: {
      get() {
        this.new_window = this.$store.state.metrics.schedule_report ? this.$store.state.metrics.schedule_report.window : ""
        return  this.$store.state.metrics.schedule_report ? this.$store.state.metrics.schedule_report.window : "";
      },
      set(data) {
        console.log(data)
        if(data){
          this.new_window = data.value
          this.$store.commit("metrics/SET_WINDOW", data.value);
        }
        else{
          this.new_window = data
        }
      },
    },
  },
  methods:
  {
    validationForm() {
      this.$refs.downloadForm.validate().then((success) => {
        if (success) {
          this.downlaodCsv();
        }
      });
    },
    downlaodCsv()
    {
      this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Processing Download',
                icon: 'Download',
                variant: 'success',
              },
            });
      let deviceId = this.$route.params.id;
      const metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-csv?device=${deviceId}&initial_date=${this.initial_date}&end_date=${this.end_date}&metric_field=${this.selectedMetricField.value}&window=${this.window.value}`
      this.$http.get(metricsEndpoint)
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `${this.selectedMetricField.label} - ${this.currentDevice.mac_number}.csv`
        link.click()
        URL.revokeObjectURL(link.href)
            
        })
        .catch( error => 
        {
             this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('Error'),
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: error.data
                    },
                  }) 
        });
    },
    validationModal(bvModalEvent){
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    checkFormValidity() {        
        if(this.new_frequency)
          this.frequencyState = true
        else 
          this.frequencyState = false

        if(this.new_window)
          this.dateIntervalState = true
        else
          this.dateIntervalState = false

        return (this.frequencyState && this.dateIntervalState)
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          console.log("entrou vali")
          return
        }
        // Push the name to submitted names
        let data = {
        "device":this.currentDevice.id,
        "frequency":this.new_frequency,
        "emails":this.new_account.toString(),
        "window":this.new_window
        }
        this.$store.dispatch("metrics/createtSchedule", {
          context: this,
          data: data,
        }).then(_ => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Schedule action",
              icon: "RefreshCwIcon",
              variant: "success",
            },
          });
        }).catch( error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Schedule action",
              icon: "AlertTriangleIcon",
              variant: "danger"
            },
          })
        } 
        )
        this.$nextTick(() => {
          this.$bvModal.hide('modal-center-schedule')
        })
        // Hide the modal manually
      },
      resetModal() {
        this.frequencyState = null
        this.accountState= null
        this.dateIntervalState= null
      },
  }
};
</script>
