<template>
  <div>
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <validation-observer ref="downloadForm">
        <b-card no-body>
          <b-card-body>
            <b-row class="justify-content-center">
              <b-col md="12" lg="6">
                <span
                  class="text-status-reports text-uppercase text-center d-block"
                  >{{$t("HACCP")}}</span
                >
                <div class="pt-1">
                  <b-row
                    class="d-flex align-items-center justify-content-center"
                  >
                    <b-col md="12" lg="3">
                      <label
                        for="data-inicio-01"
                        class="
                          text-status-reports
                          small
                          text-lg-right text-center
                          d-block
                          text-center
                        "
                        >{{$t("Initial Date")}}:</label
                      >
                    </b-col>
                    <b-col md="12" lg="5" class="d-flex justify-content-center">
                      <validation-provider
                          #default="{ errors }"
                          name="Initial Date"
                          rules="required"
                        >
                      <b-form-datepicker
                        id="data-inicio-01"
                        placeholder="dd/mm/yyyy"
                        locale="pt"
                        :hide-header="true"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="initial_date"
                        :state="errors.length > 0 ? false : null"
                        :max="new Date().toISOString()"
                      ></b-form-datepicker>
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <div class="pt-1">
                  <b-row class="align-items-center justify-content-center">
                    <b-col md="12" lg="3">
                      <label
                        for="data-fim-02"
                        class="
                          text-status-reports
                          small
                          text-lg-right text-center
                          d-block
                          text-center
                        "
                        >{{$t("End Date")}}:</label
                      >
                    </b-col>
                    <b-col md="12" lg="5" class="d-flex justify-content-center">
                        <validation-provider
                          #default="{ errors }"
                          name="End Date"
                          rules="required"
                        >
                      <b-form-datepicker
                        id="data-fim-02"
                        placeholder="dd/mm/yyyy"
                        locale="pt"
                        :hide-header="true"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        v-model="end_date"
                        :state="errors.length > 0 ? false : null"
                        :min="initial_date"
                        :max="new Date().toISOString()"
                      ></b-form-datepicker>
                       <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <div class="pt-1">
                  <b-row class="align-items-center justify-content-center">
                    <b-col md="12" lg="3">
                      <label
                        for="interval-1"
                        class="
                          text-status-reports
                          small
                          text-lg-right text-center
                          d-block
                          text-center
                        "
                        >{{$t("Date Interval")}}:</label
                      >
                    </b-col>
                    <b-col md="12" lg="5" class="d-flex justify-content-center" >
                      <validation-provider
                          #default="{ errors }"
                          name="Interval"
                          rules="required"
                        >
                        <v-select
                        style="width: 150px"
                        :placeholder="$t('Interval')"
                        :options=time_interval_options
                        :searchable="false"
                        v-model="window"
                        :reduce="(interval) => interval.value"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                    </b-col>
                  </b-row>
                </div>
                <div class="mt-4 mb-2 text-center">
                  <button type="button" class="btn search-btn mr-0 btn-none" @click="validationForm">
                    <span class="text-uppercase">{{$t("Download")}}</span>
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormDatepicker,
  BFormTimepicker
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormDatepicker,
    vSelect,
    ToastificationContent,
    ValidationProvider, 
    ValidationObserver,
    BFormTimepicker
  },
  directives: {
    "b-form-datepicker": BFormDatepicker,
    Ripple,
  },
  data() {
    return {
      initial_date:"",
      end_date:"",
      window:"15m",
      time_initial_date:"00:00",
      time_end_date:"23:59",


      //validation rules
      required,
      time_interval_options : [
        {value:'5m', label: "5m"},
        {value:'10m', label: "10m"},
        {value:'15m', label: "15m"},
        {value:'30m', label: "30m"},
        {value:'1h', label: "1h"},
        {value:'4h', label: "4h"},
        {value:'12h', label: "12h"},
        {value:'24h', label: "24h"},
      ],
    };
  },
  computed:
  {
    currentDevice()
    {
      return this.$store.state.devices.currentDevice; 
    }
  },
  methods:
  {
    validationForm() {
      this.$refs.downloadForm.validate().then((success) => {
        if (success) {
          this.downloadPDF();
        }
      });
    },
    downloadPDF()
    {
      this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Processing Download',
                icon: 'Download',
                variant: 'success',
              },
            });
      this.$store.dispatch("alerts/report", 
      { 
        context: this, 
        device_id:this.$route.params.id, 
        initial_date:`${this.initial_date} ${this.time_initial_date.slice(0,5)}`, 
        end_date:`${this.end_date} ${this.time_end_date.slice(0,5)}`,
        window: this.window,
        is_haccp:true})
      .then(
        response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        let file_name = `${this.currentDevice.model_code}-${this.currentDevice.name ? this.currentDevice.name : this.currentDevice.mac_number}.pdf` 
        link.download = file_name
        link.click()
        URL.revokeObjectURL(link.href)
      })
      .catch( error => 
        {
             this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('Error'),
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: error.data
                    },
                  }) 
        });
    }
  }
};
</script>
